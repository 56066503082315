import Notification from "./Notification";

const data = {
    appList: [
        {
            name: "startups-edu-h5-prod",
            entry: "https://h5.startups168.com/startups-edu-h5-prod/",
            container: "#container",
            activeRule: "#/edu/",
            props: {
                Notification: Notification,
            },
        },
        // {
        //     name: "startups-web",
        //     entry: "https://www.startups168.com/startups-web/",
        //     container: "#container",
        //     activeRule: (location) => {
        //         if (location.hash.startsWith("#/edu/")) {
        //             return false;
        //         } else {
        //             return true;
        //         }
        //     },
        //     props: {
        //         Notification: Notification,
        //     },
        // },
    ],
};

export default data;
